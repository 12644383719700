import { postMessage } from "@components/notifications";
import { ChartsContext } from "@lib/context/charts";
import { useSessionContext } from "@lib/context/session";
import { deleteMyChartRequest } from "@lib/network/my";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

export const useChartDelete = () => {
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const { setChartsLoading } = useContext(ChartsContext);
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const router = useRouter();

	const deleteChart = async (chartId: number) => {
		const response = await deleteMyChartRequest(
			{ id: chartId },
			accessToken,
		);

		setShowConfirmationModal(false);

		if (response.success) {
			setChartsLoading(true);
			if (router.pathname !== "/dj/charts/") router.push("/dj/charts/");
		} else {
			postMessage({
				type: "error",
				message: "Unable to delete Chart",
			});
		}
	};

	return { deleteChart, showConfirmationModal, setShowConfirmationModal };
};
