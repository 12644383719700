import Marquee from "@components/Marquee";
import ImageFallback from "@components/core/Image/ImageFallback";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { RELEASE_PLACEHOLDER } from "@lib/constants";
import { cls } from "@lib/css";
import { Release } from "@models/release";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useState } from "react";
import {
	Actions,
	Badges,
	CardActions,
	CardButton,
	CardControls,
	CardLink,
	CropLink,
	ImageWrapper,
	Meta,
	ReleaseName,
	Wrapper,
} from "./ReleaseCard.style";

interface Props {
	index: number;
	className?: string;
	release: Release;
	overrideImage?: string;
	isSearch?: boolean;
	location?: string;
	dataTestId?: string;
	handleReleaseClick?: (release: Release, index: number) => void;
}

const IMG_WIDTH = RELEASE_PLACEHOLDER.image.width;
const IMG_HEIGHT = RELEASE_PLACEHOLDER.image.height;
const FALLBACK_URL = RELEASE_PLACEHOLDER.href;

const ReleaseCard: React.FC<Props> = ({
	index,
	release,
	overrideImage,
	className,
	isSearch = false,
	location,
	dataTestId,
	handleReleaseClick,
}) => {
	const [controls, setControls] = useState<boolean>(false);
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;
	const preOrder = `${t("PreOrder")}`;

	return (
		<Wrapper
			className={cls(controls ? "actions" : undefined, className)}
			data-testid={dataTestId}
		>
			<ImageWrapper>
				{(release.exclusive || release.pre_order || release.is_hype) && (
					<Badges>
						{release.exclusive && <div className="exclusive">{exclusive.toUpperCase()}</div>}
						{release.pre_order && <div className="preorder">{preOrder.toUpperCase()}</div>}
						{release.is_hype && <div className="hype">HYPE</div>}
					</Badges>
				)}
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					title={release?.name}
					className="artwork"
					onClick={() =>
						handleReleaseClick && handleReleaseClick(release, index)}
				>
					<ImageFallback
						src={overrideImage ? overrideImage : release?.image?.uri || ""}
						alt={release?.name}
						width={IMG_WIDTH}
						height={IMG_HEIGHT}
						fallbackSrc={FALLBACK_URL}
						blurDataURL={FALLBACK_URL}
						placeholder="blur"
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</Link>
				<CardActions>
					<Actions>
						<Play releaseId={release.id} />
						<AddToQueue releaseId={release.id} />
						<AddToPlaylist releaseId={release.id} />
						<AddToCart
							location={location}
							release={release}
							isSearch={isSearch}
						/>
					</Actions>
				</CardActions>
			</ImageWrapper>
			<Meta>
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					onClick={() =>
						handleReleaseClick && handleReleaseClick(release, index)}
				>
					<CropLink title={release?.name} className="artwork">
						<Marquee>
							<ReleaseName>{release?.name}</ReleaseName>
						</Marquee>
					</CropLink>
				</Link>
				{release.artists && release.artists.length > 0 && (
					<CardLink>
						<Marquee>
							{renderArtistNames(release.artists, { location })}
						</Marquee>
					</CardLink>
				)}
				{release.label && (
					<CardLink>
						<LabelLink label={release.label} location={location} withMarquee shouldMarqueeWrap={false}>
							<CropLink title={release.label.name}>
								{release.label.name}
							</CropLink>
						</LabelLink>
					</CardLink>
				)}
			</Meta>
			<CardControls>
				<CardButton>
					<Play releaseId={release.id} />
				</CardButton>

				<CardButton
					onClick={() => {
						setControls(!controls);
					}}
				>
					<SpriteIcon id="dots" width="32" height="32" />
				</CardButton>
			</CardControls>
		</Wrapper>
	);
};

export default ReleaseCard;
