import styled from "styled-components";

export const CardLink = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  overflow: hidden;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CropLink = styled.span`
  display: block;
  width: 100%;
  height: 20px;
  overflow: hidden;
`;

export const ReleaseName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  overflow: hidden;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: fles-start;
  justify-content: flex-start;

  width: 100%;

  transition: top 0.2s ease-in-out;
  padding: 8px;
  gap: 4px;
`;

export const Actions = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  padding: 8px;

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    justify-content: space-between;
    gap: 4px;
    padding: 0 0 0 4px;
    flex-wrap: nowrap;
    &:last-child {
      span {
        border-radius: 0;
      }

      span.price {
        min-width: 50px;
        width: 50px;
      }

      span.arrow {
        padding: 4px 1px;
        width: 20px;
      }
    }
  }
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 125px;
  top: 150%;
  transition: top 0.2s ease-in-out;
  overflow: hidden;

  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  @media ${(props) => props.theme.device.lg} {
    height: 24px;
    background: #696969;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const CardButton = styled.a`
  cursor: pointer;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  cursor: pointer;
`;

export const CardControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-top: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};
  z-index: 1;

  @media ${(props) => props.theme.device.lg} {
    display: none;
  }
`;

export const Badges = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: black;
  width: 100px;

  div {
    padding: 0 8px;
  }

  div.hype {
    background: ${(props) => props.theme.colors.blue[500].accent};
  }

  div.exclusive {
    background: ${(props) => props.theme.colors.green[300].accent};
  }

  div.preorder {
    background: ${(props) => props.theme.colors.yellow[300].accent};
  }
`;

export const ImageWrapper = styled.div`
  display: block;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  &.actions ${CardActions} {
    top: calc(100% - 50px);
    visibility: visible;
    opacity: 1;
  }

  @media ${(props) => props.theme.device.lg} {
    max-width: 262px;

    ${CardActions} {
      top: unset;
      bottom: 0;
    }

    &:hover {
      ${CardActions} {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media ${(props) => props.theme.device.xl} {
    max-width: 150px;
  }
`;
